<script>
  export let baseColor = '#000000';
  export let loaderColor = '#ffffff';
  export let absolute = false;

  let containerStyle = '';
  if (absolute) {
    containerStyle = `position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%)`;
  }
</script>

{#if absolute}
  <div id="blocker" />
{/if}
<div class="container" style={containerStyle}>
  <div
    class="loader"
    style="border: 16px solid {baseColor}; border-top: 16px solid {loaderColor}"
  />
</div>

<style>
  .container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #blocker {
    background: var(--bg-color);
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  .loader {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    opacity: 0;
    animation: appear 1s forwards, spin 1s 0.1s infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
</style>
